@import url('../../styles/colors.scss');

.main {
  >header.header {
    display: flex;
    position: sticky;
    top: 0;
    padding: 12px 100px;
    justify-content: space-between;
    border-bottom: 1px solid var(--Dark-gray);
    background: rgba(2, 2, 2, 0.60);
    backdrop-filter: blur(9px);
    z-index: 1;

    .logo {
      color: var(--White);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      align-items: center;
      display: flex;
      gap: 8px;
      text-decoration: none;
    }

    .menu {
      display: flex;
      align-items: center;
      gap: 24px;

      >a {
        cursor: pointer;
        color: var(--Gray);
        text-decoration: none;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 32px;

      .langs {
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;

        .active {
          color: var(--White);
        }

        > div {
          display: block;
          border-right: 1px solid var(--Light-gray);
          height: 12px;
          display: flex;
          align-items: center;
          margin-right: 8px;
          padding-right: 8px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
            border-right: 0;
            padding-right: 0;
          }
        }

        &.hidden {
          display: none;
        }
      }
    }
  }

  >header.headerMobile {
    display: none;
    position: sticky;
    top: 0;
    justify-content: space-between;
    gap: 4px;
    padding: 12px 32px;
    background: rgba(2, 2, 2, 0.60);
    backdrop-filter: blur(9px);

    .logo {
      color: var(--White);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      align-items: center;
      display: flex;
      gap: 8px;
      text-decoration: none;
      position: relative;
      z-index: 3;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 32px;
      position: relative;
      z-index: 3;

      .langs {
        display: flex;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;

        .active {
          color: var(--White);
        }

        > div {
          display: block;
          border-right: 1px solid var(--Light-gray);
          height: 12px;
          display: flex;
          align-items: center;
          margin-right: 8px;
          padding-right: 8px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
            border-right: 0;
            padding-right: 0;
          }
        }
      }

      .menuButton {
        img {
          cursor: pointer;
          display: flex;
        }

        .close {
          display: none;
        }

        &.active {
          .hamburger {
            display: none;
          }

          .close {
            display: flex;
          }
        }
      }
    }

    .menuMobile {
      position: fixed;
      top: 0px;
      left: 0;
      width: 100%;
      height: 100vh;
      background-color: var(--Black);
      z-index: 2;
      flex-direction: column;
      justify-content: center;
      padding-left: 32px;
      gap: 32px;

      .langs {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        display: none;

        .active {
          color: var(--White);
        }

        > div {
          display: block;
          border-right: 1px solid var(--Light-gray);
          height: 12px;
          display: flex;
          align-items: center;
          margin-right: 8px;
          padding-right: 8px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
            border-right: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1310px) {
  .main {
    >header.header {
      display: none;
    }
    
    >header.headerMobile {
      display: flex;
      z-index: 9999999999;

      .menuMobile {
        display: none;

        > a {
          color: var(--White);
          font-size: 26px;
          font-weight: 500;
          line-height: normal;
          text-decoration: none;
        }
  
        &.active {
          display: flex;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    >header.header {
      display: none;
    }
    
    >header.headerMobile {
      display: flex;
      padding: 12px 16px;

      .right {
        gap: 8px;
        .langs {
          display: none;
        }
      }

      .menuMobile {
        > a {
          color: var(--White);
          font-size: 26px;
          font-weight: 500;
          line-height: normal;
          text-decoration: none;
        }

        .langs {
          display: flex;
        }
      }
    }
  }
}